<template>
    <div class="right-menu shipping-right">
    
        <subHeader pageName="REDIRECTS"/>

        <tutorialsAction />

        <div class="db-blue-section pt-0">
            <div class="db-container">
                <div class="mianBlurBox pt-0">          
                    <div class="seoDashboardTabsBlock mt-0">
                        <div class="tabsDataBox">
                            <ValidationObserver ref="SeoRedirectObserver">
                                <form>
                                    <div class="row">
                                        
                                        <!--redirect type block starts here -->
                                        <div class="col-md-4">
                                            <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                                <div class="formContentBox">
                                                    <label for="redirect-status-code-selection">Type <span>*</span></label>
                                                    <select v-model="form.status_code" @change="typeChange" id="redirect-status-code-selection" :disabled="tourIsActive">
                                                        <option selected value="" disabled>Select Type</option>
                                                        <option value="301" >301 Moved Permanently</option>
                                                        <option value="302">302 Found</option>
                                                        <option value="307">307 Temporay Redirect</option>
                                                        <option value="410">410 Content Deleted</option>
                                                        <option value="451">451 Unavailable For Legal Resaons</option>
                                                    </select>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!--redirect type block ends here -->

                                        <!-- block for status codes 301,302 and 307 starts here -->
                                        <div class="col-md-4" v-if="form.status_code == 301 || form.status_code == 302 || form.status_code == 307">
                                            <ValidationProvider 
                                                name="old url" 
                                                :rules="form.status_code == 301 || form.status_code == 302 || form.status_code == 307 ? 'required|max:250|regex:(^https?://)' : '' " 
                                                v-slot="{ errors }"
                                            >
                                                <div class="formContentBox">
                                                    <label for="oldUrl">Old URL <span>*</span></label>
                                                    <input v-model="form.from" placeholder="Old URL" type="text" name="oldUrl" id="oldUrl">
                                                    <span class="text-danger" >{{ errors[0] ? errors[0] + ' ' + '(must start with "https://") ' : '' }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-4" v-if="form.status_code == 301 || form.status_code == 302 || form.status_code == 307">
                                            <ValidationProvider 
                                                name="new url" 
                                                :rules="form.status_code == 301 || form.status_code == 302 || form.status_code == 307 ? 'required|max:250|regex:(^https?://)' : '' " 
                                                v-slot="{ errors }"
                                            >
                                                <div class="formContentBox">
                                                    <label for="newUrl">New URL <span>*</span></label>
                                                    <input v-model="form.to" placeholder="New URL" type="text" name="newUrl" id="newUrl">
                                                    <span class="text-danger" >{{ errors[0] ? errors[0] + ' ' + '(must start with "https://") ' : '' }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- block for status codes 301,302 and 307 ends here -->

                                        <!-- block for status codes 410 and 451 starts here -->
                                        <div class="col-md-4" v-if="form.status_code == 410 || form.status_code == 451">
                                            <ValidationProvider 
                                                name="old url" 
                                                :rules="form.status_code == 410 || form.status_code == 451 ? 'required|max:250|regex:(^https?://)' : '' " 
                                                v-slot="{ errors }"
                                            >
                                                <div class="formContentBox">
                                                    <label for="newUrl">Old URL <span>*</span></label>
                                                    <input v-model="form.from" placeholder="Old URL" type="text" name="newUrl" id="newUrl">
                                                    <span class="text-danger" >{{ errors[0] ? errors[0] + ' ' + '(must start with "https://") ' : '' }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- block for status codes 410 and 451 ends here -->

                                    </div>

                                    <!-- add redirect block starts here -->
                                    <div class="dataSaveBtn redirectBtn" style="text-align: right">
                                        <button v-if="!save_btn_loading" @click.prevent="validate" id="save-redirect-btn" :disabled="tourIsActive">
                                            Add Redirect
                                        </button>
                                        <loaderBtn v-else/>
                                    </div>
                                    <!-- add redirect block ends here -->

                                </form>
                            </ValidationObserver>

                            <!-- redirects table starts here -->
                            <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="redirect-table">
                                <div class="tableScrollWrap" ref="categoriesTable">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="ctmCheckWrap">
                                                        <label class="ctmTableCheckContainer">
                                                            <input 
                                                                type="checkbox"
                                                                @click="toggleIsCheckedAll()"
                                                                :checked="checkedItems.length != 0 && checkedItems.length == redirects.data.length"
                                                                :disabled="tourIsActive"
                                                            >
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </th>
                                                <th class="ctmPr-3">
                                                    <div class="ctmTableTitleWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Type">Type</p>
                                                    </div>
                                                </th>
                                                <th class="ctmPr-3">
                                                    <div class="ctmTableTitleWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Old URL">Old URL</p>
                                                    </div>
                                                </th>
                                                <th class="ctmPr-3">
                                                    <div class="ctmTableTitleWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="New URL">New URL</p>
                                                    </div>
                                                </th>
                                                <th class="ctmPr-3">
                                                    <div class="ctmTableTitleWrap">
                                                        <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="pending">
                                            <tr v-for="i in 5" :key="i">
                                                <td>
                                                    <div class="ctmCheckWrap">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="20"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="250"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="250"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <vue-skeleton-loader
                                                            type="rect"
                                                            :width="100"
                                                            :height="20"
                                                            animation="fade"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            
                                            <tr v-show="redirects.data.length == 0">
                                                <td colspan="5" style="text-align: center;">No Redirect URLs Available</td>
                                            </tr>

                                            <tr v-for="redirect in redirects.data" :key="redirect.id">

                                                <!-- checkbox & image block starts here -->
                                                <td>
                                                    <div class="ctmCheckWrap">
                                                        <label class="ctmTableCheckContainer" @click.prevent=" redirect.id != -1 ? checkedItemHandle(redirect.id) : ''">
                                                            <input 
                                                                type="checkbox" 
                                                                v-model="checkedItems" 
                                                                :value="redirect.id" 
                                                                :disabled="tourIsActive" 
                                                            >
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </td>
                                                <!-- checkbox & image block ends here -->

                                                <!-- redirect status code block starts here -->
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ redirect.status_code }}</p>
                                                    </div>
                                                </td>
                                                <!-- redirect status code block ends here -->

                                                <!-- redirect from block starts here -->
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ redirect.from }}</p>
                                                    </div>
                                                </td>
                                                <!-- redirect from block ends here -->

                                                <!-- redirect to block starts here -->
                                                <td>
                                                    <div class="tableBodyCtmTxt">
                                                        <p>{{ redirect.to ? redirect.to : '--' }}</p>
                                                    </div>
                                                </td>
                                                <!-- redirect to block ends here -->

                                                <!-- redirect action block starts here -->
                                                <td>
                                                    <div class="ctmDataTableActionBtn" v-if="isDeletingItem(redirect.id) && deleteLoader">
                                                        <loaderBtn />
                                                    </div>
                                                    <div class="ctmDataTableActionBtn" v-else>
                                                        <button class="delFunction" @click.prevent="handleDelete(redirect.id)" :disabled="tourIsActive">
                                                            <i aria-hidden="true" class="fa fa-trash-o"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                                <!-- redirect action block ends here -->

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- redirects table ends here -->

                        </div>
                    </div>
                </div>

                <!--bottom action bar starts here-->
                <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                    <div class="col-md-6 model-footer-main1">
                        <div class="bottom-bar">
                            <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != redirects.data.length">
                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                <p>SELECT ALL</p>
                            </a>
                            <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                                <p>CLEAR SELECTION</p>
                            </a>
                        </div>
                        <div class="model-footer-2">{{ checkedItems.length }}</div>
                    </div>
                    <div class="col-md-6 model-footer-main1 product-row-reverse">
                        <div class="bottom-bar">
                            <a href="" class="model-footer-1 ml-4" @click.prevent="redirectsBulkRemoval('delete')">
                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                <p>DELETE</p>
                            </a>
                        </div>
                    </div>
                </div>
                <!--bottom action bar ends here-->

            </div>
        </div>

        <!--vue tour-->
        <v-tour name="seoRedirectsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import subHeader from "@/components/subHeader.vue";
import { MessageBox, Message, Loading } from 'element-ui'
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    name: 'redirects',
    metaInfo() {
        return {
            title: 'SEO | Redirects | YeetCommerce',
        };
    },
    data() {
        return {
            form: {
                status_code: "",
                from: null,
                to: null
            },
            is_loading: false,
            save_btn_loading: false,
            
            checkedItems: [],
            deletingItems:[],
            deleteLoader:false,

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: true
                    }
                },
                //redirects steps
                {
                    target: '#redirect-status-code-selection',
                    content: `<strong>Redirect Status Code Selection</strong><br>You can choose the status code of redirect by selecting an option from this dropdown. 
                    Url options can be different depending upon the selected status code.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
                {
                    target: '#save-redirect-btn',
                    content: `<strong>Save Added Redirect</strong><br>Added redirect details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'left'
                    }
                },
                {
                    target: '#redirect-table',
                    content: `<strong>Redirects Table</strong><br>The list will show previously added redirects. 
                    To remove any redirect from the list, click on the bin icon in the action column.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            pending: 'redirect/isPending',
            redirects: 'redirect/redirects',
            error: 'redirect/error',
            message: 'redirect/message',
            logoutIsPending:'logoutIsPending'
        }),
    },
    methods:{
        typeChange(){

            this.form.from = null;
            this.form.to = null;
            this.$refs.SeoRedirectObserver.reset();

        },
        async validate(){

            this.$refs.SeoRedirectObserver.validate().then(success => {
                
                if(!success){

                    const errors = Object.entries(this.$refs.SeoRedirectObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.SeoRedirectObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    this.saveChanges();

                }
                
            });

        },
        async saveChanges(){

            if(this.form.status_code == '410' || this.form.status_code == '451'){

                this.form.to = null;

            } 

            if(this.form.from == this.form.to){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: "It's not possible to set the same URL as both the old and new.",
                });

                return

            }

            let obj = {
                from: this.form.from,
                to: this.form.to,
                status_code: this.form.status_code
            }

            this.save_btn_loading = true;

            try{
                let res = await this.$axios.post('/addRedirectUrl', obj);
                if(res.data.status_code == "1032"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });
                
                    this.resetForm();
                    this.$store.dispatch('redirect/fetchRedirectRequest');

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes("not permitted") || 
                    error.response.data.error.includes("not possible")){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });
                        
                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.save_btn_loading = false;

            }

        },
        checkUrl(value){

            try{

                let url = new URL(value);
                let path = url.pathname;
                
                if(url.search != ''){

                    path += url.search

                }

                return path;
                
            }catch(ex){

                return value;

            }

        },
        resetForm(){

            this.form = {
                status_code: "",
                from: null,
                to: null
            }

            this.$refs.SeoRedirectObserver.reset();

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        async handleDelete(id){

            MessageBox.confirm(
                'Are you sure you want to delete ?',
                'Warning',
                {
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                }
            ).then(async ()=>{

                let formData = new FormData();
                formData.append('id',id);

                this.deletingItems.push(id);
                this.deleteLoader = true;

                try{
                    let res = await this.$axios.post('/deleteRedirectUrl',formData)
                    if(res.data.status_code == "1033"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.$store.dispatch('redirect/fetchRedirectRequest');
                        
                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });
                            
                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }
                    
                }finally{

                    this.checkedItems = [];
                    this.deleteLoader = false;
                    this.deletingItems = [];

                }

            }).catch(()=>{});

        },

        //bulk action start
        async redirectsBulkRemoval(action){

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                if(action == 'delete'){
                
                    let loader = Loading.service({
                        text: "The selected redirects are being removed. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('resource_type', 'redirect');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);
                            
                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.post("/bulk-action", formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Redirects have been successfully removed.",
                            });

                            this.$store.dispatch('redirect/fetchRedirectRequest');

                        }   
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }finally{
                        
                        this.checkedItems = [];
                        this.deletingItems = [];
                        this.deleteLoader = false;

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        toggleIsCheckedAll(){

            if(!this.tourIsActive){

                if((this.checkedItems.length == this.redirects.data.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.redirects.data.length){

                    this.checkedItems = [];
                    
                    this.redirects.data.forEach(redirect => {

                        if(redirect.id != -1){

                            this.checkedItems.push(redirect.id);

                        }

                    });

                }

            }
        },
        clearSelection(){

            this.checkedItems = [];

        },
        checkedItemHandle(redirectId){

            if(!this.tourIsActive){

                let index = this.checkedItems.findIndex(item => item == redirectId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(redirectId);

                }

            }

        },

        //tour methods
        startSeoRedirectsTour(){

            this.$tours.seoRedirectsTour.start();

            this.form.status_code = "";

            this.$refs.SeoRedirectObserver.reset();

            this.tourIsActive = true;

        },
        handleNextStep(currentStep){
        },
        handlePreviousStep(currentStep){
        },
        handleTourStop(){
            
            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.tourIsActive = false;

        },

    },
    async beforeMount(){

        if(this.redirects.data.length == 0){

            try{

                this.$store.dispatch('redirect/fetchRedirectRequest');

            }catch(error){

                this.$message({
                    type: "error",
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }

        }

    },
   
}
</script>